import { useLazyQuery } from '@vue/apollo-composable';
import type { TaxonomyProjectSpreadsheetsQuery, TaxonomyProjectSpreadsheetsQueryVariables } from '@/__generated__/types';
import dayjs from '@/lib/dayjs/config';
import useCurrentUser from '@/utils/composables/useCurrentUser/useCurrentUser';
import saveFile from '@/utils/helpers/saveFile';
import waitForApolloQuery from '@/utils/helpers/waitForApolloQuery';
import TAXONOMY_PROJECT_SPREADSHEETS_QUERY from './TaxonomyProjectSpreadsheets.query';

export function useDownloadTaxonomyProjectSpreadsheets() {
  const { currentUser } = useCurrentUser();

  const query = useLazyQuery<TaxonomyProjectSpreadsheetsQuery, TaxonomyProjectSpreadsheetsQueryVariables>(
    TAXONOMY_PROJECT_SPREADSHEETS_QUERY,
    undefined,
    { fetchPolicy: 'network-only' },
  );

  const downloadSpreadsheets = async (projectId: string, projectName: string) => {
    const requestData: TaxonomyProjectSpreadsheetsQueryVariables = {
      projectId,
    };

    // eslint-disable-next-line no-unused-expressions
    query.load(undefined, requestData) || query.refetch(requestData);
    const result = await waitForApolloQuery(query);

    if (result && !(result instanceof Error)) {
      const spreadsheetsData = result.getTaxonomyProjectSpreadsheets;
      if (spreadsheetsData) {
        const company = currentUser.value?.entity.name || '';
        const date = dayjs().format('DD-MM-YYYY');
        const name = `${company}_${projectName}_taxonomy_report_${date}.xlsx`;

        saveFile(spreadsheetsData, name, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
      }
    }
  };

  return {
    loading: query.loading,
    downloadSpreadsheets,
  };
}
